import * as React from "react"
import {graphql, useStaticQuery} from "gatsby";
import Button from "../components/global/button";
import HeroImage from "../components/global/heroImage";
import Image from "../components/global/image";
import InnerLayout from "../components/global/innerLayout"
import PageTitle from "../components/global/pageTitle";
import parse from "html-react-parser"
import defaultFrontCover from "../images/coming-soon-front-cover.png"
import "./podcast.scss"

const PodcastPage = () => {

    const data = useStaticQuery(graphql`
        {
          craft {
            entry(section: "publishingPodcast") {
              ... on Craft_publishingPodcast_publishingPodcast_Entry {
                id
                pageTitle
                section2ContentTitle
                section2TextContentWithLink
                section4ContentTitle
                section5ContentTitle
                featuredBooks {
                            ... on Craft_bookPage_default_Entry {
                                id
                    slug
                    title
                    value: id
                    bookTitlePrepend
                    bookFullDescription
                    bookFrontCover {
                        key:id,
                        smallUrl: url(transform:"bookCoverLarge")
                        largeUrl: url
                        ... on Craft_websiteBookPage_Asset {
                            altText
                        }
                    }
                  }
                }
                podcastLinks {
                            ... on Craft_podcastLinks_podcastLink_BlockType {
                                image {
                      url(transform: "podcastServiceLogo")
                      ... on Craft_websiteGeneral_Asset {
                        altText
                      }
                    }
                    linkUrl
                    linkText
                  }
                }
                publishingPodcastHeroImage {
                  ... on Craft_publishingPodcastHeroImage_image_BlockType {
                    image {
                      url(transform: "heroImageTransform")
                      ... on Craft_websiteGeneral_Asset {
                        altText
                      }
                    }
                    imageLink {
                      linkUrl
                      openInNewTab
                    }
                  }
                }
              }
              seomatic {
                  metaTitleContainer
                  metaTagContainer
                  metaLinkContainer
                  metaScriptContainer
                  metaJsonLdContainer
                  metaSiteVarsContainer
                  frontendTemplateContainer
                }
            }
          }
        }
  `)

    const getBookFrontCover = (book) => {
        if (book.bookFrontCover.length > 0) {
            return book.bookFrontCover[0].smallUrl
        } else {
            return defaultFrontCover
        }
    }

    const getBookFrontCoverAltText = (book) => {
        if (book.bookFrontCover.length > 0) {
            return book.bookFrontCover[0].alt
        } else {
            return null
        }
    }

    const getFeaturedBooks = (books) => {
        return books.map(book => (
            <div className="featured-box">
                <div className="featured-box-left">
                    <a href={`/book/${book.slug}`}>
                        <Image src={getBookFrontCover(book)} alt={getBookFrontCoverAltText(book)}/>
                    </a>
                </div>
                <div className="featured-box-right">
                    <h4>{book.bookTitlePrepend} {book.title}</h4>
                    {parse(book.bookFullDescription)}
                    <Button to={`/book/${book.slug}`} classes="btn-primary" text="Learn More" openInNewTab={false}></Button>
                </div>
            </div>
        ))
    }

    const getPodcastLinks = (links) => {
        return links.map(link => (
            <li>
                <a href={link.linkUrl} target="_blank" rel="noreferrer">
                    <Image src={link.image[0].url} alt={link.image[0].alt} />
                    <span>{link.linkText}</span>
                </a>
            </li>
        ))
    }

    return (
        <InnerLayout seomatic={data.craft.entry.seomatic} entryId={data.craft.entry.id}>

            <div className="midd publishing-podcast-page">

                <PageTitle title={data.craft.entry.pageTitle}/>

                <div className="content-page">

                    <div className="container-small">

                        <HeroImage image={data.craft.entry.publishingPodcastHeroImage}/>

                        <div className="podcast-detail">
                            <h2>{data.craft.entry.section2ContentTitle}</h2>
                            {parse(data.craft.entry.section2TextContentWithLink)}
                        </div>
                    </div>

                    <div className="recent-episodes">
                        <div className="container-small">
                            <h2>Recent Episodes</h2>
                            <p className="consent">To enable the podcast player and listen here, your browser must accept marketing cookies and allow JavaScript.</p>
                        </div>
                    </div>

                    <div className="featured-book">
                        <div className="container-small">
                            <h2>{data.craft.entry.section4ContentTitle}</h2>

                            <div className="featured-list">

                                {getFeaturedBooks(data.craft.entry.featuredBooks)}

                            </div>

                        </div>
                    </div>

                    <div className="listen-book">
                        <div className="container-small">
                            <h2>{data.craft.entry.section5ContentTitle}</h2>
                            
                            <ul>
                                {getPodcastLinks(data.craft.entry.podcastLinks)}
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default PodcastPage
